import { useEffect, useState } from "react";
import { Node } from "../../model/database";
import { NodeTable } from "../../model/database/node-table";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export function useDescendantsNodes(leafId: string): Result<readonly Node[]> {
    const table = useDatabase().nodes;
    const revision = useUpdateRevision(table);
    const [result, setResult] = useState<Result<readonly Node[]>>({});

    useEffect(() => {
        const ac = new AbortController();
        const fetchNodes = async (signal: AbortSignal) => {
            const node = await table.get(leafId, { signal });
            fetchDescendantsNodes(leafId, table).then(
                (data: readonly Node[]) => {
                    if (!ac.signal.aborted) {
                        setResult({ data: [node, ...data] });
                    }
                },
                (error: unknown) => {
                    if (!ac.signal.aborted) {
                        setResult({ error });
                    }
                },
            );
        };

        fetchNodes(ac.signal).catch((error) => {
            console.error(error);
        });

        return () => {
            ac.abort();
        };
    }, [leafId, revision, table]);

    return result;
}

async function fetchDescendantsNodes(
    id: string,
    nodeTable: NodeTable,
): Promise<Node[]> {
    const childNodes: readonly Node[] = await nodeTable.findGreedily({
        index: "listNodesByParentId",
        parentId: id,
    });

    const nodes = await Promise.all(
        childNodes.map(async (child) =>
            fetchDescendantsNodes(child.id, nodeTable),
        ),
    );
    return childNodes.concat(nodes.flat());
}
